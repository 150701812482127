<template>
  <div class="front_container">
    <!-- 头部背景部分 -->
    <div class="front_top">
      <div class="title">LD前台</div>
      <div class="advantage">驾培行业主流功能、贴切行业管理架构布局、更易上手</div>
      <div class="details">
        为驾校提供行业数字化管理系统，助力驾培行业以最小的代价跟上数字化转型浪潮。以5年+多品牌的驾校运营实践经验为需求原型，设计出全新的管理系统架构，彻底改变繁琐的传统管理模式，让驾校的管理信息更简单清晰的同时，增加驾校的收益渠道，以促降本增效。
      </div>
    </div>
    <!-- 中间介绍部分 -->
    <div class="front_main">
      <!-- 产品介绍 -->
      <div class="introduce">
        <div class="title">产品介绍</div>
        <el-row type="flex" justify="center" align="center">
          <el-col :span="15">
            <div class="introduce_text">
              <div><span style="color:#1684fc">LD前台</span>,为驾校用户提供四大驾培行业刚需功能、<span
                  style="color:#1684fc">学员管理、财务管理、考试管理、学员后台</span>，同时根据驾校
                主流运营方式以及人员架构提供多职位的权限分类、结合当前驾培行业的硬件定位及从业人员等特点，以更易上手的www.网页形式作为系统载体
                、根据行业的信息类别需求，提供便捷的模版数据导入功能。</div>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="pic">
              <img :src="introduce_pic" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 核心功能 -->
      <div class="main_function">
        <div class="title">核心功能</div>
        <div class="language">FUNCTION</div>
        <el-row type="flex" justify="center" align="center">
          <el-col :span="12">
            <div class="pic">
              <img :src="function_pic" alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="function_text_box">
              <!-- 学员管理 -->
              <div class="function_item">
                <div class="title">
                  学员管理
                </div>
                <div class="details_text">学员信息查询、新增学员录入、等待完善资料、转学/退学/过期/毕业名单
                  将信息以功能为导向实现模块化，同时保留信息关联使用场景，让工作更便利清晰
                  Information modularization + Information scenario •••</div>
              </div>
              <!-- 财务管理 -->
              <div class="function_item">
                <div class="title">
                  学员管理
                </div>
                <div class="details_text">学员信息查询、新增学员录入、等待完善资料、转学/退学/过期/毕业名单
                  将信息以功能为导向实现模块化，同时保留信息关联使用场景，让工作更便利清晰
                  Information modularization + Information scenario •••</div>
              </div>
            </div>

          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="center" style="margin-top:30px">
          <el-col :span="12">
            <div class="function_text_box">
              <!-- 学员管理 -->
              <div class="function_item" style="width:500px">
                <div class="title">
                  考试管理
                </div>
                <div class="details_text">考试名单添加、预约管理、核对结果、成绩录入、预登记数据
                  以驾校当前的考试管理流程相结合，用阶梯递增模式提供考试管理解决方案
                  Incremental mode •••</div>
              </div>
              <!-- 财务管理 -->
              <div class="function_item" style="width:500px">
                <div class="title">
                  学员后台
                </div>
                <div class="details_text">广告通知、驾校/教练展示、实操课程设定/调整、学员进度/评价
                  向驾校提供“驾校·学员·教练”，即时多形式的角色互动解决方案
                  Multi role interaction •••</div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="pic">
              <img :src="function_pic" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 产品价值 -->
      <div class="product_value">
        <div class="title">产品价值</div>
        <div class="language">VALUE</div>
        <el-row type="flex" justify="center" align="center" style="margin-bottom:50px">
          <el-col :span="10">
            <div class="left">
              <div class="value_pic">
                <img :src="value1_pic" alt="">
              </div>
              <div class="title">以更低的代价，实现企业数字化转型</div>
              <div class="text">首半年免费使用·购买6个月（ ¥ 600）·购买12个月（ ¥ 980)</div>
            </div>

          </el-col>
          <el-col :span="14">
            <div class="right">
              <div class="text_list">
                <div> • 增加驾校稳定收益渠道</div>
                <div> • 7*8小时在线客服问题反馈、解决</div>
                <div> • 现场系统操作教学、教学部门人员培训</div>
                <div> • 一个系统即可全面了解学员进度</div>
                <div> • 系统自动判断学员进度，主动推送相关提示信息</div>
              </div>
              <div class="value_pic">
                <img :src="value11_pic" alt="">
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row type="flex" justify="center" align="center" style="margin-bottom:50px">
          <el-col :span="14">
            <div class="right">
              <div class="text_list">
                <div>LD前台 •</div>
                <div>LD学员 •</div>
                <div>LD教员 •</div>
                <div>LDboss •</div>
              </div>
              <div class="value_pic">
                <img :src="value22_pic" alt="">
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="left">
              <div class="value_pic">
                <img :src="value2_pic" alt="">
              </div>
              <div class="title">多平台互通</div>
              <div class="text">前台、教练、学员、老板数据实时互通</div>
            </div>

          </el-col>
        </el-row>

        <el-row type="flex" justify="center" align="center" style="margin-bottom:50px">
          <el-col :span="10">
            <div class="left">
              <div class="value_pic">
                <img :src="value3_pic" alt="">
              </div>
              <div class="title">功能强大</div>
              <div class="text">数字化信息管理之外，还是一个营销互动的强大工具</div>
            </div>

          </el-col>
          <el-col :span="14">
            <div class="right">
              <div class="text_list">
                <div> • 驾校实时的信息通知的工具</div>
                <div> • 营销推广、引导老学员推荐的工具</div>
                <div> • 促进员工与学员互动引流的工具</div>
                <div> • 展示驾校实力，教练风貌的工具</div>
                <div> • 实线无纸化管理的工具</div>
              </div>
              <div class="value_pic">
                <img :src="value33_pic" alt="">
              </div>
            </div>
          </el-col>
        </el-row>

      </div>

    </div>
    <!-- 底部 -->
    <div class="front_footer">
      <BottomFooter></BottomFooter>
    </div>

  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  export default {
    name: 'WorkspaceJsonFrontpc',
    components: {
      BottomFooter
    },

    data() {
      return {
        introduce_text: 'LD前台，为驾校用户提供四大驾培行业刚需功能、学员管理、财务管理、考试管理、学员后台，同时根据驾校的主流运营方式以及人员架构提供多职位的权限分类、结合当前驾培行业的硬件定位及从业人员等特点，以更易上手的www.网页形式作为系统载体、根据行业的信息类别需求，提供便捷的模版数据导入功能。',
        introduce_pic: require('.././assets/introduce.png'),
        function_pic: require('.././assets/function.png'),
        value1_pic: require('.././assets/value1.png'),
        value11_pic: require('.././assets/value1-1.png'),
        value2_pic: require('.././assets/value2.png'),
        value22_pic: require('.././assets/value2-2.png'),
        value3_pic: require('.././assets/value3.png'),
        value33_pic: require('.././assets/value3-3.png'),

      };
    },

    mounted() {

    },

    methods: {

    },
  };

</script>

<style lang="scss" scoped>
  .front_container {
    width: 100%;
    height: 100%;

    .front_top {
      width: 100%;
      height: 300px;
      background: url(".././assets/front_bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);
      ;
      background-size: 100% 100%;
      color: #ffffff;
      box-sizing: border-box;
      padding: 50px 0;

      .title {
        font-size: 40px;
        margin-bottom: 20px;
      }

      .advantage {
        font-size: 26px;
        margin-bottom: 20px;
      }

      .details {
        width: 80%;
        margin: 0 auto;
        font-size: 15px;
        text-align: left;
      }
    }

    .front_main {
      box-sizing: border-box;
      padding: 30px 40px;
      border-bottom: 1px solid #ccc;

      .introduce,
      .main_function,
      .product_value {
        width: 100%;

        .title {
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .language {
          color: rgba(233, 233, 233, 1);
          margin-bottom: 20px;
        }

        .introduce_text {
          width: 80%;
          height: 100%;
          text-align: left;
          line-height: 40px;
          display: flex;
          align-items: center;
        }

        .pic {
          width: 500px;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .function_text_box {
          text-align: left;
          width: 600px;

          .function_item {
            margin-bottom: 30px;

            .title {
              font-size: 18px;
              font-weight: normal;
              margin-bottom: 10px;
            }

            .details_text {
              line-height: 30px;
              color: rgba(145, 145, 145, 1);
            }
          }

        }

        .left {
          width: 100%;
          box-sizing: border-box;
          padding-left: 60px;
          text-align: left;

          .value_pic {
            width: 60px;
            height: 50px;
            margin-bottom: 20px;
          }

          .title {
            font-size: 18px;
            font-weight: 600;
          }

          .text {
            color: rgba(145, 145, 145, 1);
          }
        }

        .right {
          width: 600px;
          height: 180px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          position: relative;

          .text_list {
            text-align: left;
            margin-left: 40px;
          }

          .value_pic {
            width: 150px;
            height: 150px;
            position: absolute;
            right: -60px;
            bottom: -10px;
          }
        }


      }
    }

    .front_footer {
      height: 100px !important;
    }

  }

</style>
